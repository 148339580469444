import axios from 'axios'
import Store from '@/store/index'
import router from '@/router'
const token = localStorage.getItem('access_token')

export function getAddress () {
    axios.get('/api/Store/Basket/getAddresses',{
      headers: {
      'Authorization': `Bearer${token}`
      }
     }).then(Response => {
       console.log(Response.data)
       if (Response.data.Result.error) {
        Store.dispatch('alertError', {show: true, type: 'network'})
        return
       }
       Store.dispatch('setAddressUser', Response.data.Result)
     }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
     })
}

  export function viewAddressUser (addressID: '') {
    axios.get('/api/Store/Basket/getAddresses',{
      headers: {
      'Authorization': `Bearer${token}`
      }
     }).then(Response => {
      if (Response.data.Result.error) {
        Store.dispatch('alertError', {show: true, type: 'network'})
        return
       }
       for (const address of Response.data.Result) {
         if (address.id === addressID) {
          Store.dispatch('viewAddressUser', address)
         }
       }
     }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
     })
}

export function setAddress (address: {}) {
  Store.dispatch('load_page', true)
    axios.post('/api/Store/Basket/setAddress', address,{
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
      Store.dispatch('load_page', false)
      if (Response.data.Result.error) {
        Store.dispatch('alertSuccess', {show: true, type: 'network'})
        return
       }
        if (Response.data.Result.success == 1) {
          router.push('/my_location')
          localStorage.setItem("from_location", "come in my location")
        }
    }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
     })
}

export function deletedAddress (addressID: '') {
    axios.post('/api/Store/Basket/deleteAddress', 
    {
        address_id: addressID
    },{
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
      if (Response.data.Result.error) {
        Store.dispatch('alertError', {show: true, type: 'network'})
        return
       }
        if (Response.data.Result == true) {
            router.push('/my_location')
        }
    }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
     })
}